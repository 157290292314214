.tiles {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-bottom: 75px;
}

.tiles .tile {
  margin: 0 20px;
}

.tiles .tile .heading {
  font-size: 1.5em;
}

.tiles .tile .image img {
  width: 100%;
  height: auto;
  max-width: 300px;
}

/* From tablet width onwards */
@media (min-width: 768px) {
  .tiles {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    flex-direction: row;
    justify-content: space-evenly;
  }

  .tiles .tile .image img {
    max-height: 500px;
    max-width: 400px;
  }
}
