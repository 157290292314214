.overview {
  text-align: center;
}

.overview .heading {
  font-size: 1.5em;
}

.overview > p {
  margin-bottom: 50px;
  margin-left: auto;
  margin-right: auto;
  max-width: 700px;
}

.overview .overview-item {
  display: flex;
  flex-direction: column-reverse;
}

.overview .overview-item {
  margin-bottom: 80px;
}

.overview .overview-item .description .heading {
  margin: 0;
  font-size: 1.2em;
}

.overview .overview-item .description {
  min-height: 200px;
  border-top: 15px solid var(--theme-color-bg-2);
  background-color: var(--theme-color-bg-3);
  box-shadow: 0 4px 8px 0 #999;
  /* box-shadow: 5px 5px 15px #999; */
  padding: 20px 30px;
}

.overview .overview-item img {
  width: 100%;
  height: auto;
  max-width: 550px;
}

/* From tablet width onwards */
@media (min-width: 768px) {
  .overview {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  .overview .overview-item {
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
  }

  .overview .overview-item:nth-child(even) {
    flex-direction: row-reverse;
  }

  .overview .overview-item .description {
    max-width: 500px;
    min-width: 200px;
    border-radius: 8px;
  }

  .overview .overview-item .image {
    display: flex;
    align-items: center;
  }

  .overview .overview-item img {
    max-height: 500px;
    width: auto;
    min-width: 300px;
    max-width: 100%;
  }
}
