@import url("./banner.css");
@import url("./contact-us.css");
@import url("./footer.css");
@import url("./form.css");
@import url("./header.css");
@import url("./button.css");
@import url("./overview.css");
@import url("./results.css");
@import url("./tiles.css");

:root {
  --theme-color-bg-1: #ffae00;
  --theme-color-bg-2: #ffcf67;
  --theme-color-bg-3: #ffffff;
  --theme-color-fg-1: #000000;
  --theme-color-fg-2: #252525;

  --theme-color-hover: rgba(0, 0, 0, 0.12);
}

* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: "Barlow", sans-serif;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
}

body > main {
  flex-grow: 1;
}

p {
  line-height: 1.5;
}

a {
  text-decoration: none;
  color: var(--theme-color-fg-1);
}

a:hover {
  text-decoration: underline;
}

.icon {
  color: var(--theme-color-fg-1);
}

.icon svg {
  font-size: inherit;
  vertical-align: -0.125em;
  fill: currentColor;
  stroke: currentColor;
}

.icon.icon-email svg {
  vertical-align: -0.225em;
  margin-right: 5px;
}
