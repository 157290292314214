.banner {
  display: flex;
  flex-direction: column-reverse;
  margin: 50px 20px 75px 20px;
  text-align: center;
}

.banner .description .heading {
  font-size: 1.7em;
}

.banner .description .heading.lone {
  font-size: 2.5em;
}

.banner .image img {
  width: 100%;
  height: auto;
}

/* From tablet width onwards */
@media (min-width: 768px) {
  .banner {
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .banner .description .heading.lone {
    text-align: right;
  }

  .banner .description {
    width: 400px;
    min-width: 300px;
  }

  .banner .image {
    width: 800px;
    margin-left: 50px;
  }

  .banner .image.small img {
    height: 400px;
    width: auto;
  }
}
