.contact-us .heading {
  font-size: 1.5em;
}

.contact-us .contact-form,
.contact-us .contact-details {
  border-top: 15px solid var(--theme-color-bg-2);
  background-color: var(--theme-color-bg-3);
  box-shadow: 0 4px 8px 0 #999;
  text-align: center;
  margin-bottom: 80px;
  padding: 20px 30px;
}

.contact-us .contact-form .input-subject {
  display: none;
}

.contact-us .contact-form .form-status {
  font-size: 0.9em;
  margin: 10px;
}

.contact-us .contact-form .form-status > * {
  display: none;
  font-weight: bold;
}

.contact-us .contact-form .form-status .success {
  color: #056805;
}

.contact-us .contact-form .form-status .error {
  color: #ff0000;
}

/* From tablet width onwards */
@media (min-width: 768px) {
  .contact-us .contact-form,
  .contact-us .contact-details {
    margin-left: auto;
    margin-right: auto;
    max-width: 500px;
    min-width: 200px;
    border-radius: 8px;
  }
}
