form.form {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

form.form label {
  display: block;
  margin-bottom: 5px;
}

form.form label,
form.form .button {
  margin-top: 15px;
}

form.form input,
form.form textarea {
  font-family: inherit;
  font-size: 1.1em;
  border-radius: 5px;
  border: 2px solid var(--theme-color-fg-1);
  color: var(--theme-color-fg-1);
  padding: 10px;
  width: 100%;
}

form.form input:focus,
form.form textarea:focus {
  background-color: var(--theme-color-hover);
}

form.form textarea {
  resize: vertical;
}
