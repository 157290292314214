a.button {
  text-decoration: none;
  display: inline-block;
}

button.button {
  border: none;
}

.button {
  color: var(--theme-color-fg-1);
  padding: 10px 15px;
  margin-top: 5px;
  border-radius: 5px;
  background-color: var(--theme-color-bg-2);
  /* box-shadow: 0 2px 4px 2px #999; */
  transition: all 0.25s ease-in-out;
  font-weight: bold;
  font-size: inherit;
  user-select: none;
}

.button:hover {
  box-shadow: 0 2px 4px 2px #999;
  /* box-shadow: 0 3px 5px 3px #999; */
  transform: translateY(-2px);
  transition: all 0.15s ease-in-out;
  text-decoration: none;
}

.button:active {
  box-shadow: none;
  transform: translateY(0px);
  transition: all 0.15s ease-in-out;
}
