header {
  padding: 10px;
  position: sticky;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  align-self: flex-start;
  width: 100%;
  background-color: var(--theme-color-bg-1);
}

header a:hover {
  text-decoration: none;
}

header .logo {
  display: inline-block;
  margin: 0 15px;
}

header .logo img {
  vertical-align: -0.3em;
  width: auto;
  height: 44px;
}

header .menu-btn {
  padding: 4px 6px;
  border: none;
  background-color: transparent;
  border-radius: 10%;
  transition: background-color 0.5s ease-out;
}

header .menu-btn:hover {
  background-color: var(--theme-color-hover);
  transition: background-color 0.5s ease-in-out;
}

header .icon.icon-menu {
  font-size: 1.7em;
}

header .icon.icon-menu svg {
  vertical-align: -0.2em;
}

header .nav-bar.open::after {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.75);
}

header .nav-bar ul {
  padding: 0;
  list-style-type: none;
}

header .nav-bar > ul {
  margin: 0;
  padding: 10px;
  position: fixed;
  top: 0;
  left: -75%;
  z-index: 2;
  bottom: 0;
  max-width: 70%;
  background-color: var(--theme-color-bg-3);
  box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.5);
  border-right: 7px var(--theme-color-bg-1) solid;
  transition: left 0.4s ease-out;
  overflow-y: scroll;
}

header .nav-bar.open > ul {
  left: 0;
  transition: left 0.4s ease-in-out;
}

header .nav-bar > ul li {
  display: block;
  font-weight: bold;
}

header .nav-bar > ul li ul {
  margin-left: 15px;
}

header .nav-bar > ul li ul li {
  font-weight: normal;
}

header .nav-bar > ul .submenu-heading,
header .nav-bar > ul a {
  padding: 15px;
  color: var(--theme-color-fg-1);
  display: block;
  border-radius: 0 15px 15px 0;
  transition: background-color 0.5s ease-out;
  outline: none;
}

header .nav-bar > ul a:hover,
header .nav-bar > ul a:focus {
  background-color: var(--theme-color-hover);
  transition: background-color 0.5s ease-in-out;
}

header .nav-bar > ul a:active {
  background-color: var(--theme-color-bg-2);
  transition: background-color 0.2s ease-out;
}

/* From tablet width onwards */
@media (min-width: 768px) {
  header {
    justify-content: space-between;
  }

  header .menu-btn {
    display: none;
  }

  header .logo img {
    height: 50px;
  }

  header .nav-bar {
    display: inline-block;
  }

  header .nav-bar.open::after {
    content: none;
  }

  header .nav-bar > ul {
    padding: 0;
    position: relative;
    left: auto;
    top: auto;
    z-index: auto;
    max-width: none;
    background: none;
    box-shadow: none;
    border-right: none;
    transition: none;
    display: flex;
    overflow-y: visible;
  }

  header .nav-bar > ul > li {
    margin: 0 2px;
  }

  header .nav-bar > ul a {
    border-radius: 12px;
  }

  header .nav-bar > ul a:hover,
  header .nav-bar > ul a:focus {
    border-radius: 15px;
    transition: border-radius 0.3s ease-in-out;
  }

  header .nav-bar > ul li .submenu-heading {
    padding: 15px;
    display: inline-block;
    border-radius: 15px 15px 0 0;
    cursor: pointer;
    user-select: none;
  }

  header .nav-bar > ul li ul {
    margin-left: 0;
    position: absolute;
    background-color: var(--theme-color-bg-2);
    border-radius: 0 5px 5px 5px;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.5s ease-in-out;
  }

  header .nav-bar > ul li:focus-within .submenu-heading,
  header .nav-bar > ul li:hover .submenu-heading {
    background-color: var(--theme-color-bg-2);
    transition: background-color 0.5s ease-in-out;
  }

  header .nav-bar > ul li:hover ul,
  header .nav-bar > ul li:focus ul,
  header .nav-bar > ul li:active ul,
  header .nav-bar > ul li ul:focus-within {
    opacity: 1;
    pointer-events: all;
    transition: opacity 0.5s ease-in-out;
  }
}
